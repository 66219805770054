.main-section {
    min-height: 500px;

    video {
        object-fit: cover;
        min-height: 500px;
        max-height: 700px;
        
    }
}


.overlay {
    z-index: 1;
    left: 0;
    background: #0914166b;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAAXNSR0IArs4c6QAAABdJREFUGFdjZGBg+M/AwMDIACNADAwOACNxAQTGOwk4AAAAAElFTkSuQmCC);
    height: calc(100% - 6px);


    h1 {
        color: var(--e-global-color-primary);
        text-transform: uppercase;
        letter-spacing: 8px;
        font-family: var(--e-global-typography-primary-font-family), Sans-serif;
        background: linear-gradient(180deg, var(--e-global-color-primary) 0%, var(--e-global-color-secondary) 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        font-size: 13px;
        letter-spacing: 10px;
        font-family: var(--e-global-typography-text-font-family), Sans-serif;
        text-transform: uppercase;
    }

    .clients_section {
        span {
            color: var(--e-global-color-primary);
            font-size: 9px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-family: var(--e-global-typography-primary-font-family), Sans-serif;
        }

        .col {
            max-width: 120px;
            max-height: 70px;
        }


    }
}