.categories-item {
    a {
        text-decoration: none;
        font-family: var(--e-global-typography-accent-font-family), sans-serif;
        font-size: 32px;
        line-height: .50em;
        color: #FFFFFF;
        fill: #FFFFFF;
        font-weight: 800;
        transition: .4s;

        &:hover {
            color: var(--e-global-color-secondary);
        }
    }
}


.social-icons {
    .social-icon {
        color: var(--e-global-color-secondary);
        transition: .2s;

        &:hover {
            color: var(--e-global-color-accent);
            padding-bottom: 5px;
        }
    }
}