.main-button {
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    color: #051214;
    background-color: var(--e-global-color-accent);
    border-radius: 100px 100px 100px 100px;
    padding: 16px 57px 16px 57px;
    font-family: var(--e-global-typography-primary-font-family), Sans-serif;
    border: none;
    transition: .3s;

    &:hover {
        transform: scale(1.1);
        background-color: #fff;
    }
}