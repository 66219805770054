header {

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    background-color: var(--e-global-color-3c33e6d);
    left: 0;
    top: 0;
    z-index: 1000;
    max-height: 80px;
    min-height: 80px;


    &.down {
        top: -80px;
    }
}