@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');

:root {
    --e-global-color-primary: #A8C7B7;
    --e-global-color-secondary: #3b7078;
    --e-global-color-text: #A8C7B7;
    --e-global-color-accent: #40FEC4;
    --e-global-color-909f5e1: #FFFFFF;
    --e-global-color-3c33e6d: #051214;
    --e-global-typography-primary-font-family: "Nunito";
    --e-global-typography-secondary-font-family: "Nunito";
    --e-global-typography-text-font-family: "Nunito";
    --e-global-typography-accent-font-family: "Nunito";
}

html,
body,
#root {
    min-height: 100vh;
    background-color: var(--e-global-color-3c33e6d);
}

html {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
        width: 12px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }
}

.line-holder {
    position: fixed;
    top: 0;
    min-height: 100vh;
    pointer-events: none;
    z-index: 1001;
    width: calc(100% - 100px);
    left: 50px;

    .vertical-line {
        position: absolute;
        top: 0;
        width: 2px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.03);
    }

    .left {
        left: 0;
    }

    .left2 {
        left: 25%;
        transform: translateX(-25%);
    }

    .center {
        left: 50%;
        transform: translateX(-50%);
    }

    .right2 {
        right: 25%;
        transform: translateX(25%);
    }

    .right {
        right: 0;
    }
}