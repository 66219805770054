footer {
    .footer-heading {
        color: var(--e-global-color-secondary);
        font-size: 20px;
        letter-spacing: 10px;
        line-height: 1.4em;
        font-family: var(--e-global-typography-primary-font-family), Sans-serif;
        background: linear-gradient(180deg, var(--e-global-color-primary) 0%, var(--e-global-color-secondary) 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .footer-nav {
        border-top: solid 1px var(--e-global-color-secondary);
        border-bottom: solid 1px var(--e-global-color-secondary);

        .footer-nav-item {
            color: var(--e-global-color-secondary);
            font-family: var(--e-global-typography-primary-font-family), Sans-serif;
            background: linear-gradient(180deg, var(--e-global-color-primary) 0%, var(--e-global-color-secondary) 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 15px;
        }
    }
}