.carousel-section {

    img {
        max-height: 500px;
        min-height: 500px;
        object-fit: cover;


    }

    .carousel-title {
        font-family: Roboto;
        text-transform: uppercase;
        color: white;
        text-align: left;
        letter-spacing: 10px;
        font-size: 20px;
        text-align: center;

    }

    .section-subheading {
        color: var(--e-global-color-4f42a913);
        font-size: 11px;
        letter-spacing: 6px;
        line-height: 36px;
        text-transform: uppercase;
        font-family: var(--e-global-typography-primary-font-family), Sans-serif;
        font-weight: 200;
        font-style: normal;
    }

    .section-heading {
        color: var(--e-global-color-secondary);
        font-weight: 700;
        letter-spacing: 1px;
        font-style: normal;
        font-size: 36px;
        line-height: 36px;
        text-transform: uppercase;
        font-family: var(--e-global-typography-primary-font-family), Sans-serif;
        background: linear-gradient(180deg, var(--e-global-color-primary) 0%, var(--e-global-color-secondary) 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    .description {
        color: var(--e-global-color-text);
        font-size: 18px;
    }

}

@media (max-width: 768px) {
    .carousel-section {

        img {
            max-height: 400px;
            min-height: 400px;
            max-width: 400px;
        }
    }
}