.background-overlay {

    background: url('https://armeniansound.am/wp-content/uploads/2021/07/IMG_1348-scaled.jpg') no-repeat center center fixed;
    background-size: cover;

    .content {
        background-color: rgba(0, 0, 0, 0.75);
    }


    .heading {
        .title {

            font-size: 36px;
            font-weight: 700;
            line-height: 1em;
            letter-spacing: 1px;

            .title1 {
                color: var(--e-global-color-primary);
            }

            .title2 {
                color: var(--e-global-color-secondary);
            }
        }

        p {
            font-size: 12px;
            letter-spacing: 2px;
        }

    }



    .card {

        &:hover {

            .card-img {
                transform: scale(1.15);
            }


            .card-overlay {
                background: none;
            }
        }

        .card-img {
            transition: all .9s;
        }

        .card-overlay {
            background: rgba(0, 0, 0, 0.324);
            transition: all .9s;

            .products-card-title {
                color: var(--e-global-color-primary);
                font-size: 36px;
                font-family: var(--e-global-typography-primary-font-family), sans-serif;
            }

            .products-card-subtitle {
                font-size: 15px;
                line-height: 36px;
                font-family: var(--e-global-typography-text-font-family), Sans-serif;

            }
        }
    }


}

@media (max-width: 768px) {
    .background-overlay {

        background: url('https://armeniansound.am/wp-content/uploads/2021/07/IMG_1348-scaled.jpg') center center no-repeat;
        background-size: cover;
    }
}