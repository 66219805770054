.waveform-playlist {

   .waveform-playlist-track {

    &:hover {
      background: rgba(0, 0, 0, 0.278);
    }

    .waveform-playlist-waveform {
      flex: 1;
    }

    .waveform-playlist-title {
      font-size: 1.2rem;
      color: #ffffff;
    }

    .waveform-playlist-button:hover {
      opacity: 0.7;
    }
  }
}